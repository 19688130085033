<template>
  <!-- Navbar goes here -->
  <nav
    class="z-50 w-screen bg-transparent saoltext transition duration-500"
    :class="{
      'bg-np-light':
        scrollPosition > 0 || isMobileMenuOpen || $route.name !== 'Home',
      fixed: $route.name === 'Home',
      'sticky top-0': $route.name !== 'Home',
    }"
  >
    <div class="max-w-screen-lg mx-auto px-4 lg:px-8">
      <div
        v-if="!openCalendarStartDate && !openCalendarEndDate"
        class="flex justify-between h-20 lg:h-28 items-center"
      >
        <div class="flex space-x-4">
          <div class="flex-none">
            <!-- Website Logo -->
            <router-link
              v-if="!isMobileMenuOpen"
              to="/"
              class="items-center py-4 px-2"
            >
              <div
                class="w-16 h-16 lg:w-20 lg:h-20 flex justify-center items-center"
                v-if="$route.name === 'Home'"
              >
                <img
                  :src="headerImageLightUrl"
                  class="w-16 h-16 lg:w-20 lg:h-20 absolute opacity-1 transition duration-200"
                  :class="{
                    'opacity-0': scrollPosition > 0 || isMobileMenuOpen,
                  }"
                />
                <div class="w-16 h-16">
                  <img
                    :src="headerSmallImageUrl"
                    class="w-8 h-16 absolute opacity-1 transition duration-200"
                    :class="{
                      'opacity-0': scrollPosition <= 0 && !isMobileMenuOpen,
                    }"
                  />
                </div>
              </div>
              <div
                class="w-16 h-16 lg:w-20 lg:h-20 flex justify-center items-center"
                v-if="$route.name !== 'Home'"
              >
                <img
                  :src="headerImageGreenUrl"
                  class="w-16 h-16 lg:w-20 lg:h-20 absolute opacity-1 transition duration-200"
                  :class="{
                    'opacity-0': scrollPosition > 0 || isMobileMenuOpen,
                  }"
                />
                <div class="w-16 h-16">
                  <img
                    :src="headerSmallImageUrl"
                    class="w-8 h-16 absolute opacity-1 transition duration-200"
                    :class="{
                      'opacity-0': scrollPosition <= 0 && !isMobileMenuOpen,
                    }"
                  />
                </div>
              </div>
            </router-link>
          </div>
          <!-- Primary Navbar items -->
          <div
            class="hidden lg:flex items-center space-x-8 text-np-light"
            :class="{
              'text-np-green':
                scrollPosition > 0 ||
                isMobileMenuOpen ||
                $route.name !== 'Home',
            }"
          >
            <div class="dropdown">
              <button
                class="py-2 px-2 font-light transition duration-500 text-sky-600 hover:text-sky-700"
              >
                <router-link to="/about">about</router-link>
                <chevron-down-icon class="absolute" />
              </button>
              <ul
                class="dropdown-menu bg-np-light text-np-green text-left absolute min-w-40"
              >
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link to="/about" class="block whitespace-no-wrap"
                      >about neera</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/about/eco-conscious"
                      class="block whitespace-no-wrap"
                      >neera eco–conscious</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/about/neighborhoods"
                      class="block whitespace-no-wrap"
                      >neera neighborhood</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="py-2 px-2 font-light transition duration-500">
                <router-link to="/rooms">rooms</router-link>
                <chevron-down-icon class="absolute" />
              </button>
              <ul
                class="dropdown-menu bg-np-light text-np-green text-left absolute min-w-40"
              >
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link to="/rooms" class="block whitespace-no-wrap"
                      >all rooms</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/rooms/well"
                      class="block whitespace-no-wrap"
                      >well &ndash; studio</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/rooms/pond"
                      class="block whitespace-no-wrap"
                      >pond &ndash; deluxe</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/rooms/mindful"
                      class="block whitespace-no-wrap"
                      >mindful &ndash; signature</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/rooms/lagoon"
                      class="block whitespace-no-wrap"
                      >lagoon &ndash; family suite</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/rooms/lake"
                      class="block whitespace-no-wrap"
                      >lake &ndash; panoramic suite</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="py-2 px-2 font-light transition duration-500">
                <router-link to="/experiences">experiences</router-link>
                <chevron-down-icon class="absolute" />
              </button>
              <ul
                class="dropdown-menu bg-np-light text-np-green text-left absolute min-w-40"
              >
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/experiences"
                      class="block whitespace-no-wrap"
                      >neera experiences</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/experiences/activities"
                      class="block whitespace-no-wrap"
                      >activities</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/experiences/eco-wedding"
                      class="block whitespace-no-wrap"
                      >eco–wedding</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/experiences/spaces"
                      class="block whitespace-no-wrap"
                      >spaces</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
            <router-link
              to="/eat-drink"
              class="py-4 px-2 font-light transition duration-500"
              >eat & drink</router-link
            >
            <router-link
              to="/offers"
              class="py-4 px-2 font-light transition duration-500"
              >offers</router-link
            >
            <router-link
              to="/galleries"
              class="py-4 px-2 font-light transition duration-500"
              >galleries</router-link
            >
            <div class="dropdown">
              <button class="py-2 px-2 font-light transition duration-500">
                <router-link to="/whats-new">media room</router-link>
                <chevron-down-icon class="absolute" />
              </button>
              <ul
                class="dropdown-menu bg-np-light text-np-green text-left absolute min-w-40"
              >
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/whats-new"
                      class="block whitespace-no-wrap"
                      >what's new</router-link
                    >
                  </div>
                </li>
                <li class="px-2 bg-np-light hover:bg-np-fog">
                  <div class="border-np-grey py-2">
                    <router-link
                      to="/media-inquiry"
                      class="block whitespace-no-wrap"
                      >media inquiry</router-link
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Secondary Navbar items -->
        <div class="hidden lg:flex items-center space-x-3">
          <booking-button />
        </div>
        <!-- Mobile menu button -->
        <div class="lg:hidden flex items-center">
          <button
            class="outline-none mobile-menu-button w-8"
            @click="toggleMobileHeader"
          >
            <!-- <svg
              v-if="!isMobileMenuOpen"
              class="w-8 h-8 text-np-light"
              :class="{
                'text-np-green':
                  scrollPosition > 0 ||
                  isMobileMenuOpen ||
                  $route.name !== 'Home',
              }"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg> -->

            <!-- <lottie-animation
              :path="hamburgerLottieUrl"
              :loop="false"
              :auto-play="true"
              :speed="1"
              :width="256"
              :height="256"
            /> -->

            <svg
              v-if="!isMobileMenuOpen"
              class="w-8 h-8 text-np-light"
              :class="{
                'text-np-green':
                  scrollPosition > 0 ||
                  isMobileMenuOpen ||
                  $route.name !== 'Home',
              }"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M28 1H0M28 11H0M28 21H0" />
            </svg>

            <svg
              class="w-8 h-8 text-np-light"
              v-else
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.7072 1.70711C16.0977 1.31659 16.0977 0.683427 15.7072 0.292899C15.3167 -0.0976284 14.6835 -0.0976337 14.293 0.292887L8.00006 6.58574L1.70737 0.293056C1.31685 -0.0974684 0.683684 -0.0974684 0.29316 0.293056C-0.0973645 0.68358 -0.0973645 1.31675 0.29316 1.70727L6.58583 7.99994L0.292899 14.2928C-0.0976278 14.6833 -0.0976333 15.3165 0.292888 15.707C0.683409 16.0975 1.31657 16.0975 1.7071 15.707L8.00005 9.41416L14.2932 15.7073C14.6837 16.0978 15.3168 16.0978 15.7074 15.7073C16.0979 15.3167 16.0979 14.6836 15.7074 14.2931L9.41427 7.99995L15.7072 1.70711Z"
                fill="#5C623F"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      class="mobile-menu lg:hidden w-screen transition duration-500"
      :class="
        isMobileMenuOpen
          ? 'visible opacity-1 h-screen'
          : 'invisible opacity-0 h-0'
      "
    >
      <div>
        <ul
          class="bg-np-light text-np-green text-left divide-y divide-np-grey px-8 mb-4"
        >
          <li class="divide-y divide-np-grey">
            <button
              @click="isMobileAboutOpen = !isMobileAboutOpen"
              class="w-full bg-np-light text-2xl px-2 py-4 font-light flex justify-between items-center"
            >
              <router-link to="/about">about</router-link>
              <chevron-down-icon class="h-6" />
            </button>
            <transition name="fade" mode="out-in">
              <ul
                class="bg-np-light text-np-green text-left divide-y divide-np-grey"
                v-if="isMobileAboutOpen"
              >
                <li>
                  <router-link
                    to="/about"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >about neera</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/eco-conscious"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >neera eco–conscious</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/about/neighborhoods"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >neera neighborhood</router-link
                  >
                </li>
              </ul>
            </transition>
          </li>
          <li class="divide-y divide-np-grey">
            <button
              @click="isMobileRoomsOpen = !isMobileRoomsOpen"
              class="w-full bg-np-light text-2xl px-2 py-4 font-light flex justify-between items-center"
            >
              <router-link to="/rooms">rooms</router-link>
              <chevron-down-icon class="h-6" />
            </button>
            <transition name="fade" mode="out-in">
              <ul
                class="bg-np-light text-np-green text-left divide-y divide-np-grey"
                v-if="isMobileRoomsOpen"
              >
                <li class="">
                  <router-link
                    to="/rooms"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >all rooms</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/rooms/well"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >well &ndash; studio</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/rooms/pond"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >pond &ndash; deluxe</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/rooms/mindful"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >mindful &ndash; signature</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/rooms/lagoon"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >lagoon &ndash; family suite</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/rooms/lake"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >lake &ndash; panoramic suite</router-link
                  >
                </li>
              </ul>
            </transition>
          </li>
          <li class="divide-y divide-np-grey">
            <button
              @click="isMobileExperienceOpen = !isMobileExperienceOpen"
              class="w-full bg-np-light text-2xl px-2 py-4 font-light flex justify-between items-center"
            >
              <router-link to="/experiences">experiences</router-link>
              <chevron-down-icon class="h-6" />
            </button>
            <transition name="fade" mode="out-in">
              <ul
                class="bg-np-light text-np-green text-left divide-y divide-np-grey"
                v-if="isMobileExperienceOpen"
              >
                <li class="">
                  <router-link
                    to="/experiences"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >neera experiences</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/experiences/activities"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >activities</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/experiences/eco-wedding"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >eco–wedding</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/experiences/spaces"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >spaces</router-link
                  >
                </li>
              </ul>
            </transition>
          </li>
          <li>
            <router-link
              to="/eat-drink"
              class="block bg-np-light text-2xl px-2 py-4 font-light"
              >eat & drink</router-link
            >
          </li>
          <li>
            <router-link
              to="/offers"
              class="block bg-np-light text-2xl px-2 py-4 font-light"
              >offers</router-link
            >
          </li>
          <li>
            <router-link
              to="/galleries"
              class="block bg-np-light text-2xl px-2 py-4 font-light"
              >galleries</router-link
            >
          </li>
          <li class="divide-y divide-np-grey">
            <button
              @click="isMobileMediaRoomOpen = !isMobileMediaRoomOpen"
              class="w-full bg-np-light text-2xl px-2 py-4 font-light flex justify-between items-center"
            >
              <router-link to="/whats-new">media room</router-link>
              <chevron-down-icon class="h-6" />
            </button>
            <transition name="fade" mode="out-in">
              <ul
                class="bg-np-light text-np-green text-left divide-y divide-np-grey"
                v-if="isMobileMediaRoomOpen"
              >
                <li class="">
                  <router-link
                    to="/whats-new"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >what's new</router-link
                  >
                </li>
                <li class="">
                  <router-link
                    to="/media-inquiry"
                    class="block bg-np-fog text-xl px-2 py-4 font-light"
                    >media inquiry</router-link
                  >
                </li>
              </ul>
            </transition>
          </li>
        </ul>
        <div class="flex justify-start px-8">
          <button
            @click="checkAvailablity"
            class="py-2 px-4 mt-4 saoltext text-np-light text-2xl bg-np-glimpse rounded-np-10 hover:opacity-60"
          >
            check availability
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ChevronDownIcon from "vue-material-design-icons/ChevronDown.vue";
import dayjs from "dayjs";
import { getSiteminderLink } from "@/api/siteminder";
import BookingButton from "./BookingButton.vue";
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  name: "Header",
  data() {
    return {
      startDate: null,
      endDate: null,
      bed: 1,
      guest: 1,
      scrollPosition: null,
      isMobileMenuOpen: false,
      isMobileCheckRoom: false,
      isMobileAboutOpen: false,
      isMobileRoomsOpen: false,
      isMobileExperienceOpen: false,
      isMobileMediaRoomOpen: false,
      openCalendarStartDate: false,
      openCalendarEndDate: false,
    };
  },
  components: {
    BookingButton,
    ChevronDownIcon,
    // LottieAnimation
  },
  computed: {
    availableStartDate() {
      var date = new Date();
      return date;
    },
    availableStartEndDate() {
      var date = this.startDate;
      date.setDate(date.getDate() + 1);
      return date;
    },
    headerImageLightUrl() {
      return require("@/assets/logo.svg");
    },
    headerImageGreenUrl() {
      return require("@/assets/logo-dark.svg");
    },
    headerSmallImageUrl() {
      return require("@/assets/logo-sm.svg");
    },
    startDateDisplay() {
      let value = new dayjs(this.startDate).format("MMM D, YYYY");
      return value.toLowerCase();
    },
    endDateDisplay() {
      let value = new dayjs(this.endDate).format("MMM D, YYYY");
      return value.toLowerCase();
    },
    // hamburgerLottieUrl() {
    //   return 'lotties/hamburger-menu.json'
    // }
  },
  watch: {
    isMobileMenuOpen: {
      handler: async function (isMobileMenuOpen) {
        if (!isMobileMenuOpen) {
          this.isMobileAboutOpen = false;
          this.isMobileRoomsOpen = false;
          this.isMobileExperienceOpen = false;
          this.isMobileMediaRoomOpen = false;
        }
      },
    },
    "$route.path": {
      handler: async function (current_path) {
        this.isMobileMenuOpen = false;
        if (current_path === "/") this.isHome = true;
        else this.isHome = false;
      },
    },
  },
  methods: {
    toggleCalendarStartDate() {
      this.openCalendarStartDate = !this.openCalendarStartDate;
    },
    toggleCalendarEndDate() {
      this.openCalendarEndDate = !this.openCalendarEndDate;
    },
    toggleBookingPanel() {
      this.isMobileCheckRoom = !this.isMobileCheckRoom;
    },
    toggleMobileHeader() {
      if (this.isMobileMenuOpen) {
        this.isMobileCheckRoom = false;
        this.openCalendarStartDate = false;
        this.openCalendarEndDate = false;
      }
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    checkAvailablity() {
      const url = getSiteminderLink(
        dayjs(this.startDate).format("YYYY-MM-DD"),
        dayjs(this.endDate).format("YYYY-MM-DD"),
        this.guest,
        this.bed
      );
      if (this.$vssWidth >= 1024) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
  },
  mounted() {
    this.startDate = new Date();
    var date = new Date();
    date.setDate(date.getDate() + 1);
    this.endDate = date;
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}

.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  visibility: hidden;
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.dropdown-menu li a {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.material-design-icon.icon-2x .material-design-icon__svg {
  height: 2em;
  width: 2em;
}

input,
select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;

  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

nav {
  font-weight: 300;
}

.dropdown-menu li:not(:last-child) div {
  border-bottom-width: 1px;
}
</style>
