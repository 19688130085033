import apiClient from "@/api/client";

export const getNewsType = (lang) => {
  return apiClient.request({
    method: "GET",
    url: `api/news-types?locale=${lang}`,
  });
};

export const getNewsList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/news-items?populate[0]=image&populate[1]=type&populate[2]=cardImage&locale=${lang}${filter}&sort[0]=pin:desc&sort[1]=id:desc&pagination[pageSize]=100`,
  });
};

export const getOtherNewsList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/news-items?populate[0]=image&populate[1]=type&populate[2]=cardImage&sort[0]=pin:desc&sort[1]=id:desc&pagination[pageSize]=100&locale=${lang}${filter}`,
  });
};

export const getNewsById = (lang, newsId) => {
  return apiClient.request({
    method: "GET",
    url: `api/news-items/${newsId}?populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};

export const getNewsBySlug = (lang, slug) => {
  return apiClient.request({
    method: "GET",
    url: `api/news-items?filters[slug]=${slug}&populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};
