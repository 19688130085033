import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import "./style.css";
import "./assets/tailwind.css";
import "tw-elements";
import 'v-calendar/dist/style.css';
import 'flowbite';
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import VueScreen from 'vue-screen';
import 'aos/dist/aos.css'
import { VueScreenSizeMixin } from 'vue-screen-size';
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { useLoading } from  './stores'

const app = createApp(App);

const {loading, setLoading} = useLoading()
app.provide('loading', loading)
app.provide('setLoading', setLoading)
router.beforeEach((to, from, next) => {
    if(to.path === from.path){
        next()
    }else{
        setLoading(true)
        setTimeout(() =>   next(), 750);
        setTimeout(() =>   setLoading(false), 2000);
    }
    // if((from && to ) && from.path !== to.path){
        
    // }
})

app.use(router);
app.use(SetupCalendar, {})
app.use(VueScreen, 'tailwind')
app.use(LottieAnimation)
app.component('Calendar', Calendar)
app.component('DatePicker', DatePicker)
app.mixin(VueScreenSizeMixin);

app.mount("#app");
