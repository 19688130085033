import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
// import Landing from '../views/Landing.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Landing',
  //   component: Landing
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/abouts/Index.vue"),
  },
  {
    path: "/about/eco-conscious",
    name: "neera eco-conscious",
    component: () => import("../views/abouts/EcoConcious.vue"),
  },
  {
    path: "/about/neighborhoods",
    name: "neera neighborhood",
    component: () => import("../views/abouts/Neighborhood.vue"),
  },
  {
    path: "/about/neighborhood/:slug",
    name: "neera neighborhood - detail",
    component: () => import("../views/abouts/NeighborhoodDetail.vue"),
  },
  {
    path: "/rooms",
    name: "Room",
    component: () => import("../views/rooms/Index.vue"),
  },
  {
    path: "/rooms/well",
    name: "Room - Well",
    component: () => import("../views/rooms/Well.vue"),
  },
  {
    path: "/rooms/pond",
    name: "Room - Pond",
    component: () => import("../views/rooms/Pond.vue"),
  },
  {
    path: "/rooms/lake",
    name: "Room - Lake",
    component: () => import("../views/rooms/Lake.vue"),
  },
  {
    path: "/rooms/lagoon",
    name: "Room - Lagoon",
    component: () => import("../views/rooms/Lagoon.vue"),
  },
  {
    path: "/rooms/mindful",
    name: "Room - Mindful",
    component: () => import("../views/rooms/Mindful.vue"),
  },
  {
    path: "/experiences",
    name: "Experiences",
    component: () => import("../views/experiences/Index.vue"),
  },
  {
    path: "/experiences/activities",
    name: "Experiences - Activities",
    component: () => import("../views/experiences/Activities.vue"),
  },
  {
    path: "/experiences/eco-wedding",
    name: "Experiences - Eco–wedding",
    component: () => import("../views/experiences/EcoWedding.vue"),
  },
  {
    path: "/experiences/spaces",
    name: "Experiences - Spaces",
    component: () => import("../views/experiences/Spaces.vue"),
  },
  {
    path: "/experiences/activity/:activity_id",
    name: "Experiences - Activity Detail",
    component: () => import("../views/experiences/ActivityDetail.vue"),
  },
  {
    path: "/eat-drink",
    name: "eat & drink",
    component: () => import("../views/eat-drinks/Index.vue"),
  },
  {
    path: "/offers",
    name: "offers",
    component: () => import("../views/offers/Index.vue"),
  },
  {
    path: "/offer/:offer_id",
    name: "offer",
    component: () => import("../views/offers/Detail.vue"),
  },
  {
    path: "/galleries",
    name: "gallery",
    component: () => import("../views/gallery/Index.vue"),
  },
  {
    path: "/whats-new",
    name: "what's new",
    component: () => import("../views/whatsnew/Index.vue"),
  },
  {
    path: "/whats-new/:news_id",
    name: "what's new detail",
    component: () => import("../views/whatsnew/Detail.vue"),
  },
  {
    path: "/media-inquiry",
    name: "Media Inquiry",
    component: () => import("../views/media-inquiry/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
