<template>
  <div
    class="flex flex-col lg:flex-row md:justify-between px-4 lg:px-8 max-w-screen-lg lg:mx-auto space-y-10 lg:space-y-0"
  >
    <div class="flex-1 flex flex-col overflow-hidden lg:w-auto lg:mt-0">
      <div
        class="flex overflow-hidden justify-center lg:justify-end lg:items-end"
      >
        <button
          class="py-2 px-4 saoltext text-np-glimpse hover:text-white rounded-np-10 border border-np-glimpse whitespace-nowrap hover:bg-np-glimpse"
          @click="checkAvailablity"
        >
          check availability
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { getSiteminderLink } from "@/api/siteminder";

dayjs.extend(customParseFormat);
export default {
  name: "Home",
  components: {},
  data() {
    return {
      startDate: null,
      endDate: null,
      guest: 1,
      bed: 1,
      showMobileToggle: false,
      showHomeBooking: false,
      isPhone: false,
    };
  },
  updated() {
    if (this.$screen) {
      console.log("updated", this.$screen.width);
      this.isPhone = this.$screen.width < 1024;
    }
  },
  mounted() {
    this.isPhone = this.$screen.width < 1024;
  },
  methods: {
    toggleBookingPanel() {
      console.log("toggleBookingPanel", this.showMobileToggle);
      this.showMobileToggle = !this.showMobileToggle;
      console.log("toggleBookingPanel", this.showMobileToggle);
    },
    toggleHomeBooking() {
      console.log("toggleHomeBooking", this.showHomeBooking);
      this.showHomeBooking = !this.showHomeBooking;
      console.log("toggleHomeBooking", this.showHomeBooking);
    },
    getDateFormat(dateStr) {
      if (!dateStr) {
        return "";
      }
      const day = new dayjs(dateStr, "DD/MM/YYYY");
      let value = day.format("MMM D, YYYY");
      return value.toLowerCase();
    },
    getAvailableStartEndDate(date) {
      const temp = date;
      const tomorrow = new Date(temp);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
    checkAvailablity() {
      let startDate = this.startDate ? this.startDate : new Date();
      let endDate = this.endDate
        ? this.endDate
        : this.getAvailableStartEndDate(startDate);
      const url = getSiteminderLink(
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        this.guest,
        this.bed
      );
      if (this.isPhone) window.location.href = url;
      else window.open(url);
    },
  },
};
</script>

<style scoped>
.hero {
  background-image: url(~@/assets/home/HOME_Cover.png);
}

@media (max-width: 768px) {
  .hero {
    background-image: url(~@/assets/home/mobile/HOME_Cover.png);
  }
}

.booking {
  background: rgba(206, 205, 200, 1);
}

input,
select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  height: 42px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

.text-litepie-secondary-700,
.placeholder-litepie-secondary-400,
.text-litepie-secondary-100,
.placeholder-litepie-secondary-500 {
  color: #666;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.description-special-offer {
  font-weight: 300;
}

.content-para {
  font-weight: 300;
  color: #5c623f;
}

.padding {
  padding-left: 10%;
  padding-right: 10%;
}

.special-offer {
  background: #e7e5dc;
}

.gallery-label {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #f0efea;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
