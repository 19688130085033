<template>
  <div data-view>
    <div class="hero bg-cover bg-center flex w-full h-screen" />
    <div
      class="flex flex-col md:flex-row booking justify-center lg:justify-between py-10 lg:py-4"
    >
      <booking-panel />
    </div>

    <quote-break
      data-aos="fade-up"
      class="my-18 lg:my-20"
      title='
      <div class="space-y-6">
        <p
          class="text-np-56 lg:text-np-64 saolitalic neera-text-green"
        >
          “be the start of <span class="whitespace-nowrap">the ripple</span>.”
        </p>
        <p
          class="text-np-xl neera-text-green saoltext"
        >
          &mdash; neera retreat hotel
        </p>
      </div>'
      description="<p><span class='saoltext whitespace-nowrap'>‘neera retreat’</span> occupies a beautiful parcel of land on the Tha Chin River that engulfs lifelong connections with the enchanting waterway and the riverside way of life. the river’s flow is the currents of life that endlessly and continuously bring change from one place to another.</p><p>The Ripple Effect was at the heart of the hotel’s conception – the notion that a single droplet of water creates a modest ripple that grows and extends, causing waves across the entire water surface. in the same way, widespread sustainable change can be created by a small change made by an individual.</p>"
      exploreLink="/about"
      exploreTitle="read our concept"
    />
    <experience-card
      data-aos="fade-up"
      id="eco-conscious"
      title="<span class='whitespace-nowrap'>eco–conscious &</span> inspirational space"
      :imageUrl="require('@/assets/home/HOME_ecoconscoious.png')"
      imgPosition="left"
    >
      <div class="space-y-2">
        <div>
          <p class="neera-text-green content-para">
            <span class="saoltext whitespace-nowrap">‘neera retreat’</span> will be mindfully preparing and building through our eco-conscious and conscious sharing throughout our first year to verify for these three certificates., GSTC (Global Sustainable Tourism Council), Green Hotel / Green Leaf, and Green Restaurant.
          </p>
        </div>
        <div>
          <neera-anchor linkTo="/experiences/activities">
            read more
          </neera-anchor>
        </div>
      </div>
    </experience-card>

    <div class="w-screen my-18 lg:my-20">
      <div class="flex relative">
        <gallery-item
          data-aos="fade-up"
          gallery-id="gallery-neera-green-space"
          :images="galleries"
        />
      </div>
    </div>

    <div class="space-y-18 lg:space-y-20 mb-20 lg:mg-0">
      <experience-card
        data-aos="fade-up"
        id="eco-wedding"
        title="<span class='whitespace-nowrap'>experience</span> <span class='whitespace-nowrap'>eco–wedding</span>"
        :imageUrl="require('@/assets/home/HOME_ecowedding.png')"
        imgPosition="right"
      >
        <div class="space-y-2">
          <div class="neera-text-green content-para space-y-4">
            <p>
              <span class="saoltext whitespace-nowrap">‘neera retreat’</span>
              was carefully designed as a perfect balance between contemporary
              aesthetics and environmental friendliness.
            </p>
            <p>
              craft up the wedding of your dreams in either our outdoor
              <span class="saoltext whitespace-nowrap"
                >'neera green space'</span
              >
              with natural lighting and an amazing river view of the
              <span class="whitespace-nowrap">Tha Chin River</span>
              or have a private ceremony indoors with our unique &
              eco-decorations.
            </p>
          </div>
          <div>
            <neera-anchor linkTo="/experiences/eco-wedding">
              read more
            </neera-anchor>
          </div>
        </div>
      </experience-card>

      <experience-card
        data-aos="fade-up"
        id="rooms"
        title="<span class='whitespace-nowrap'>better living with</span> <span class='whitespace-nowrap'>our rooms</span>"
        :imageUrl="require('@/assets/home/HOME_betterliving.png')"
        imgPosition="left"
      >
        <div class="space-y-2">
          <div>
            <p class="neera-text-green content-para">
              <span class="saoltext whitespace-nowrap">‘neera retreat’</span>
              offers 53 rooms and suites that are harmoniously and stylishly
              arranged in three wings – Sun, Moon, and Cloud, where guests
              can choose to indulge in the serenity of lush, tropical gardens or
              marvel at a picturesque river that is rich in history and culture.
            </p>
          </div>
          <div>
            <neera-anchor linkTo="/rooms"> read more </neera-anchor>
          </div>
        </div>
      </experience-card>
    </div>
    <special-item type="offer" title-list="special offers" />
  </div>
</template>

<script>
import SpecialItem from "@/components/SpecialItem.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import ExperienceCard from "@/views/experiences/components/ExperienceCard.vue";
import QuoteBreak from "@/components/QuoteBreak.vue";
import AOS from "aos";
import NeeraAnchor from "../components/NeeraAnchor.vue";
import BookingPanel from "../components/BookingPanel.vue";

export default {
  name: "Home",
  components: {
    SpecialItem,
    GalleryItem,
    ExperienceCard,
    QuoteBreak,
    NeeraAnchor,
    BookingPanel,
  },
  created() {
    const title = "neera retreat hotel";
    const description =
      "neera retreat is the pioneer of eco–conscious hotel choice located in Nakhon Pathom, Thailand, providing inspirational space to educate and create behavior change in humanity.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      guest: 1,
      bed: 1,
      showMobileToggle: false,
      showHomeBooking: false,
      isPhone: false,
      galleries: [
        {
          title: "neera green space",
          image_url: require("@/assets/home/galleries/neera_green_space.png"),
          mobile_image_url: require("@/assets/home/galleries/neera_green_space.png"),
        },
        {
          title: "at noon",
          image_url: require("@/assets/home/galleries/at_noon.png"),
          mobile_image_url: require("@/assets/home/galleries/at_noon.png"),
        },
        {
          title: "at dawn",
          image_url: require("@/assets/home/galleries/at_dawn.png"),
          mobile_image_url: require("@/assets/home/galleries/at_dawn.png"),
        },
        {
          title: "at dusk",
          image_url: require("@/assets/home/galleries/at_dusk.png"),
          mobile_image_url: require("@/assets/home/galleries/at_dusk.png"),
        },
        {
          title: "at night",
          image_url: require("@/assets/home/galleries/at_night.png"),
          mobile_image_url: require("@/assets/home/galleries/at_night.png"),
        },
      ],
    };
  },
  computed: {
    showLabel() {
      return false;
      // if (this.isPhone) return false;
      // return this.startDate || this.endDate || this.guest || this.bed;
    },
  },
  updated() {
    if (this.$screen) {
      console.log("updated", this.$screen.width);
      this.isPhone = this.$screen.width < 1024;
    }
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
    this.isPhone = this.$screen.width < 1024;
  },
};
</script>

<style scoped>
.hero {
  background-image: url(~@/assets/home/HOME_Cover.png);
}

@media (max-width: 768px) {
  .hero {
    background-image: url(~@/assets/home/mobile/HOME_Cover.png);
  }
}

.booking {
  background: rgba(206, 205, 200, 1);
}

input,
select {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  height: 42px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

.text-litepie-secondary-700,
.placeholder-litepie-secondary-400,
.text-litepie-secondary-100,
.placeholder-litepie-secondary-500 {
  color: #666;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.description-special-offer {
  font-weight: 300;
}

.content-para {
  font-weight: 300;
  color: #5c623f;
}

.padding {
  padding-left: 10%;
  padding-right: 10%;
}

.special-offer {
  background: #e7e5dc;
}

.gallery-label {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #f0efea;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
