import axios from "axios";
import config from "@/config";

const apiClient = axios.create({
  baseURL: config.apiUrl,
  timeout: config.apiTimeout,
});

apiClient.defaults.headers.common["Content-Type"] = "application/json";

export default apiClient;
