export default {
  apiUrl: "https://api.neeraretreathotel.com",
  rooms: {
    'well' : {
      name: "well",
      type: "studio",
      link: "studio",
      image_url: require("@/assets/rooms/Rooms_02-01well.png"),
      intro: "30sqm rooms with garden view and 2 single beds on floors 2, 3, and 4.",
      fullname: "better living in ‘well’",
      description:
        "<p>live ‘well’, in our compact yet spacious studio room with 2 single beds in up to 30sqm located on the 2nd, 3rd and 4th floor offering you all you need for a peaceful stay at <span class='saoltext'>‘neera retreat’</span>.</p>",
      size: "30",
      unit: "32",
      imageUrl: require("@/assets/rooms/well/well_01.png"),
      highlights: [
        {
          iconUrl: require("@/assets/rooms/icon/garden.svg"),
          name: "garden view",
        },
        {
          iconUrl: require("@/assets/rooms/icon/double-bed.svg"),
          name: "2 single beds",
        },
        {
          iconUrl: require("@/assets/rooms/icon/shower.svg"),
          name: "ensuite bathroom",
        },
      ],
      roomImages: [
        {
          image_url: require("@/assets/rooms/well/Room_well_02slide_01.png"),
          mobile_image_url: require("@/assets/rooms/well/Room_well_02slide_01.png"),
        },
        {
          image_url: require("@/assets/rooms/well/well-02slide_02.png"),
          mobile_image_url: require("@/assets/rooms/well/well-02slide_02.png"),
        },
        {
          image_url: require("@/assets/rooms/well/well-02slide_03.png"),
          mobile_image_url: require("@/assets/rooms/well/well-02slide_03.png"),
        },
        {
          image_url: require("@/assets/rooms/well/well-02slide_04.png"),
          mobile_image_url: require("@/assets/rooms/well/well-02slide_04.png"),
        },
      ],
      galleries: [
        {
          image_url: require("@/assets/rooms/well/well-03_layoutphoto_01.png"),
        },
        {
          image_url: require("@/assets/rooms/well/well-03_layoutphoto_02.png"),
        },
        {
          image_url: require("@/assets/rooms/well/Room_well_03-layoutphoto_02.png"),
        },
      ],
      roomFacilities: {
        imageUrl: require("@/assets/rooms/well/well_floorplan.svg"),
        facilities: [
          {
            iconUrl: require("@/assets/rooms/icon/neera-eco-kit.svg"),
            name: "neera eco-kit",
          },
          { iconUrl: require("@/assets/rooms/icon/wifi.svg"), name: "wifi" },
          {
            iconUrl: require("@/assets/rooms/icon/dedicate-workspace.svg"),
            name: "dedicated workspace",
          },
          {
            iconUrl: require("@/assets/rooms/icon/stationary-set.svg"),
            name: "stationery set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/smartTV.svg"),
            name: "43” smart TV",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-fridge.svg"),
            name: "mini fridge",
          },
          {
            iconUrl: require("@/assets/rooms/icon/refill-water-bottle.svg"),
            name: "refill water bottle",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-bar.svg"),
            name: "mini bar",
          },
          {
            iconUrl: require("@/assets/rooms/icon/organic-tea.svg"),
            name: "organic tea",
          },
          {
            iconUrl: require("@/assets/rooms/icon/coffee-set.svg"),
            name: "coffee set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/room-safe.svg"),
            name: "room safe",
          },
          {
            iconUrl: require("@/assets/rooms/icon/shower.svg"),
            name: "shower",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bathroom-amenities.svg"),
            name: "bathroom amenities",
          },
          {
            iconUrl: require("@/assets/rooms/icon/linen-towels.svg"),
            name: "linen + towels",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bean-bag.svg"),
            name: "beanbag",
          },
          {
            iconUrl: require("@/assets/rooms/icon/inspiration-book.svg"), 
            name: "inspirational books"
          },
        ],
      },
    },
    'pond':{
      name: "pond",
      type: "deluxe",
      link: "deluxe",
      image_url: require("@/assets/rooms/Rooms_02-02pond.png"),
      intro: "34sqm rooms with <span class='whitespace-nowrap'>private balcony</span>, river view and king-size bed on floors 3 and 4.",
      fullname: "better living in ‘pond’",
      description:
      "<p>this 34 sqm room on the 3rd floor is perfect for a mindful getaway with a comfortable king-size bed overviewing the river and get access to your own private balcony and an ensuite bathroom.</p>",
      size: "34",
      unit: "10",
      imageUrl: require("@/assets/rooms/pond/Room_pond_01.png"),
      highlights: [
        {
          iconUrl: require("@/assets/rooms/icon/river.svg"),
          name: "river view with <span class='whitespace-nowrap'>private balcony</span>",
        },
        {
          iconUrl: require("@/assets/rooms/icon/kingsize-bed.svg"),
          name: "king-size bed",
        },
        {
          iconUrl: require("@/assets/rooms/icon/shower.svg"),
          name: "ensuite bathroom",
        },
      ],
      roomImages: [
      {
        image_url: require("@/assets/rooms/pond/pond-02slide_01.png"),
        mobile_image_url: require("@/assets/rooms/pond/pond-02slide_01.png"),
      },
      {
        image_url: require("@/assets/rooms/pond/pond-02slide_02.png"),
        mobile_image_url: require("@/assets/rooms/pond/pond-02slide_02.png"),
      },
      {
        image_url: require("@/assets/rooms/pond/Room_pond_02slide_03.png"),
        mobile_image_url: require("@/assets/rooms/pond/Room_pond_02slide_03.png"),
      },
      ],
      galleries: [
        {
          image_url: require("@/assets/rooms/pond/Room_pond_03-layoutphoto_03.png"),
        },

      {
        image_url: require("@/assets/rooms/pond/pond-03_layoutphoto_02.png"),
      },
      {
        image_url: require("@/assets/rooms/pond/Room_pond_03-layoutphoto_02.png"),
      },
 

      ],
      roomFacilities: {
      imageUrl: require("@/assets/rooms/pond/pond_floorplan.svg"),
      facilities: [
        {
          iconUrl: require("@/assets/rooms/icon/neera-eco-kit.svg"),
          name: "neera eco-kit",
        },
        { iconUrl: require("@/assets/rooms/icon/wifi.svg"), name: "wifi" },
        {
          iconUrl: require("@/assets/rooms/icon/dedicate-workspace.svg"),
          name: "dedicated workspace",
        },
        {
          iconUrl: require("@/assets/rooms/icon/stationary-set.svg"),
          name: "stationery set",
        },
        {
          iconUrl: require("@/assets/rooms/icon/smartTV.svg"),
          name: "43” smart TV",
        },
        {
          iconUrl: require("@/assets/rooms/icon/mini-fridge.svg"),
          name: "mini fridge",
        },
        {
          iconUrl: require("@/assets/rooms/icon/refill-water-bottle.svg"),
          name: "refill water bottle",
        },
        {
          iconUrl: require("@/assets/rooms/icon/mini-bar.svg"),
          name: "mini bar",
        },
        {
          iconUrl: require("@/assets/rooms/icon/organic-tea.svg"),
          name: "organic tea",
        },
        {
          iconUrl: require("@/assets/rooms/icon/coffee-set.svg"),
          name: "coffee set",
        },
        {
          iconUrl: require("@/assets/rooms/icon/room-safe.svg"),
          name: "room safe",
        },
        {
          iconUrl: require("@/assets/rooms/icon/shower.svg"),
          name: "shower",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bathroom-amenities.svg"),
          name: "bathroom amenities",
        },
        {
          iconUrl: require("@/assets/rooms/icon/linen-towels.svg"),
          name: "linen + towels",
        },
        {
          iconUrl: require("@/assets/rooms/icon/inspiration-book.svg"), 
          name: "inspirational books"
        },
      ],
      },
    },
    'mindful':{
      name: "mindful",
      type: "signature deluxe",
      link: "signature-deluxe",
      image_url: require("@/assets/rooms/Rooms_02-03mindful.png"),
      intro: "34sqm rooms with <span class='whitespace-nowrap'>private balcony</span>, river view, a king-size bed and well-selected special mindful amenities on floor 4.",
      fullname: "better living in ‘mindful’",
      description: "<p>experience mindfulness and unplug in our <span class='saoltext'>‘neera’</span> signature 'mindful' room, located on the 4th floor overlooking the <span class='whitespace-nowrap'>Tha Chin River</span> view with a king-size bed, well-selected special mindful amenities, and activities offered in the 32sqm space.</p>",
      size: "34",
      unit: "6",
      imageUrl: require("@/assets/rooms/mindful/mindful-01.png"),
      highlights: [
        {
          iconUrl: require("@/assets/rooms/icon/river.svg"),
          name: "river view with <span class='whitespace-nowrap'>private balcony</span>",
        },
        {
          iconUrl: require("@/assets/rooms/icon/kingsize-bed.svg"),
          name: "king-size bed",
        },
        {
          iconUrl: require("@/assets/rooms/icon/shower.svg"),
          name: "ensuite bathroom",
        },
      ],
      roomImages: [
      {
        image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_01.png"),
        mobile_image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_01.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/mindful-02slide_02.png"),
        mobile_image_url: require("@/assets/rooms/mindful/mindful-02slide_02.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/mindful-02slide_01.png"),
        mobile_image_url: require("@/assets/rooms/mindful/mindful-02slide_01.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_04.png"),
        mobile_image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_04.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_05.png"),
        mobile_image_url: require("@/assets/rooms/mindful/Room_mindful_02slide_05.png"),
      },
      ],
      galleries: [
      {
        image_url: require("@/assets/rooms/mindful/mindful-03_layoutphoto_01.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/mindful-03_layoutphoto_02.png"),
      },
      {
        image_url: require("@/assets/rooms/mindful/Room_mindful_03_layoutphoto_02.png"),
      },

      ],
      roomFacilities: {
      imageUrl: require("@/assets/rooms/mindful/mindful_floorplan.svg"),
      facilities: [
        {
          iconUrl: require("@/assets/rooms/icon/neera-eco-kit.svg"),
          name: "neera eco-kit",
        },
        { iconUrl: require("@/assets/rooms/icon/wifi.svg"), name: "wifi" },
        {
          iconUrl: require("@/assets/rooms/icon/dedicate-workspace.svg"),
          name: "dedicated workspace",
        },
        {
          iconUrl: require("@/assets/rooms/icon/stationary-set.svg"),
          name: "stationery set",
        },
        {
          iconUrl: require("@/assets/rooms/icon/mini-fridge.svg"),
          name: "mini fridge",
        },
        {
          iconUrl: require("@/assets/rooms/icon/refill-water-bottle.svg"),
          name: "refill water bottle",
        },
        {
          iconUrl: require("@/assets/rooms/icon/mini-bar.svg"),
          name: "mini bar",
        },
        {
          iconUrl: require("@/assets/rooms/icon/organic-tea.svg"),
          name: "organic tea",
        },
        {
          iconUrl: require("@/assets/rooms/icon/coffee-set.svg"),
          name: "coffee set",
        },
        {
          iconUrl: require("@/assets/rooms/icon/mini-drip-kettle.svg"),
          name: "mini drip kettle",
        },
        {
          iconUrl: require("@/assets/rooms/icon/room-safe.svg"),
          name: "room safe",
        },
        {
          iconUrl: require("@/assets/rooms/icon/shower.svg"),
          name: "shower",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bathroom-amenities.svg"),
          name: "bathroom amenities",
        },
        {
          iconUrl: require("@/assets/rooms/icon/linen-towels.svg"),
          name: "linen + towels",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bluetooth-speaker.svg"),
          name: "bluetooth speaker",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bean-bag.svg"),
          name: "beanbag",
        },
        {
          iconUrl: require("@/assets/rooms/icon/diffuser-essential-oils.svg"),
          name: "diffuser with essential oils",
        },
        {
          iconUrl: require("@/assets/rooms/icon/inspiration-book.svg"),
          name: "inspirational books",
        },
        {
          iconUrl: require("@/assets/rooms/icon/yoga-mat.svg"),
          name: "yoga mat",
        },
      ],
      },
    },
    'lagoon':{
      name: "lagoon",
      type: "family suite",
      link: "family-suite",
      image_url: require("@/assets/rooms/Room_AllRooms_05-lagoon.png"),
      intro:
        "70sqm rooms on the top floor with <span class='whitespace-nowrap'>private balcony</span>, river view, 2 double queen-size beds, and bathtub on floor 5.",
      size: "70",
      unit: "2",
      fullname:"better living in ‘lagoon’",
      description:"<p>perfect for families or a group of 3–4 friends, our ‘lagoon’ located on the top floor (5th floor) spreads over 70 sqm with 2 double queen-size beds, a private balcony with a view, and a bathtub to unwind or for a fun time with the children.</p>",
      imageUrl:require('@/assets/rooms/lagoon/Room_lagoon_01.png'),
      highlights: [
        {
          iconUrl: require("@/assets/rooms/icon/river.svg"),
          name: "river view with <span class='whitespace-nowrap'>private balcony</span>",
        },
        {
          iconUrl: require("@/assets/rooms/icon/double-queenbed.svg"),
          name: "2 double<br/>queen-size beds",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bathtub.svg"),
          name: "ensuite bathroom",
        },
      ],
      galleries: [
        {
          image_url: require("@/assets/rooms/lagoon/lagoon-03_layoutphoto_01.png"),
        },
        {
          image_url: require("@/assets/rooms/lagoon/lagoon-03_layoutphoto_02.png"),
        },
        {
          image_url: require("@/assets/rooms/lagoon/lagoon-03_layoutphoto_03.png"),
        },
      ],
      roomImages: [
        {
          image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_01.png"),
          mobile_image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_01.png"),
        },
        {
          image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_02.png"),
          mobile_image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_02.png"),
        },
        {
          image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_03.png"),
          mobile_image_url: require("@/assets/rooms/lagoon/Room_lagoon_02slide_03.png"),
        },
      ],

      roomFacilities: {
        imageUrl: require("@/assets/rooms/lagoon/lagoon_floorplan.svg"),
        facilities: [
          {
            iconUrl: require("@/assets/rooms/icon/neera-eco-kit.svg"),
            name: "neera eco-kit",
          },
          { iconUrl: require("@/assets/rooms/icon/wifi.svg"), name: "wifi" },
          {
            iconUrl: require("@/assets/rooms/icon/dedicate-workspace.svg"),
            name: "dedicated workspace",
          },
          {
            iconUrl: require("@/assets/rooms/icon/stationary-set.svg"),
            name: "stationery set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/smartTV.svg"),
            name: "50” smart TV",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-fridge.svg"),
            name: "mini fridge",
          },
          {
            iconUrl: require("@/assets/rooms/icon/refill-water-bottle.svg"),
            name: "refill water bottle",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-bar.svg"),
            name: "mini bar",
          },
          {
            iconUrl: require("@/assets/rooms/icon/organic-tea.svg"),
            name: "organic tea",
          },
          {
            iconUrl: require("@/assets/rooms/icon/coffee-set.svg"),
            name: "coffee set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/room-safe.svg"),
            name: "room safe",
          },
          {
            iconUrl: require("@/assets/rooms/icon/shower.svg"),
            name: "shower",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bathtub.svg"),
            name: "bathtub"
          },
          {
            iconUrl: require("@/assets/rooms/icon/bathroom-amenities.svg"),
            name: "bathroom amenities",
          },
          {
            iconUrl: require("@/assets/rooms/icon/linen-towels.svg"),
            name: "linen + towels",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bean-bag.svg"),
            name: "beanbag",
          },
          {
            iconUrl: require("@/assets/rooms/icon/inspiration-book.svg"),
            name: "inspirational and children’s books",
          },
        ],
      },
    },
    'lake':{
      name: "lake",
      type: "panoramic suite",
      link: "paranomic-suite",
      image_url: require("@/assets/rooms/Room_AllRooms_06-lake.png"),
      intro: "72sqm rooms on the top floor with <span class='whitespace-nowrap'>private balcony</span>, panoramic river view, king-size bed, and bathtub.",
      fullname:"better living in ‘lake’",
      description:"<p>take advantage of the breathtaking panoramic sights of the <span class='whitespace-nowrap'>Tha Chin River</span>  on the 4th & 5th floor, ‘lake’ gives you a total of 72sqm of space equipped with a king-size bed, private balcony, and a dreamy bathtub with a view.</p>",
      imageUrl:require('@/assets/rooms/lake/Room_lake_01.png'),
      size: "72",
      unit: "3",
      highlights: [
        {
          iconUrl: require("@/assets/rooms/icon/river.svg"),
          name: "river view with <span class='whitespace-nowrap'>private balcony</span>",
        },
        {
          iconUrl: require("@/assets/rooms/icon/kingsize-bed.svg"),
          name: "king-size bed",
        },
        {
          iconUrl: require("@/assets/rooms/icon/bathtub.svg"),
          name: "ensuite bathroom",
        },
      ],
      galleries: [
        {
          image_url: require("@/assets/rooms/lake/Room_lake_03_layoutphoto_03.png"),
        },
        {
          image_url: require("@/assets/rooms/lake/Room_lake_03_layoutphoto_01.png"),
        },
        {
          image_url: require("@/assets/rooms/lake/Room_lake_03_layoutphoto_02.png"),
        },
      ],
      roomImages: [
        {
          image_url: require("@/assets/rooms/lake/Room_lake_02slide_01.png"),
          mobile_image_url: require("@/assets/rooms/lake/Room_lake_02slide_01.png"),
        },
        {
          image_url: require("@/assets/rooms/lake/Room_lake_02slide_02.png"),
          mobile_image_url: require("@/assets/rooms/lake/Room_lake_02slide_02.png"),
        },
        {
          image_url: require("@/assets/rooms/lake/Room_lake_02slide_03.png"),
          mobile_image_url: require("@/assets/rooms/lake/Room_lake_02slide_03.png"),
        },
      ],
      roomFacilities: {
        imageUrl: require("@/assets/rooms/lake/lake_floorplan.svg"),
        facilities: [
          {
            iconUrl: require("@/assets/rooms/icon/neera-eco-kit.svg"),
            name: "neera eco-kit",
          },
          { iconUrl: require("@/assets/rooms/icon/wifi.svg"), name: "wifi" },
          {
            iconUrl: require("@/assets/rooms/icon/dedicate-workspace.svg"),
            name: "dedicated workspace",
          },
          {
            iconUrl: require("@/assets/rooms/icon/stationary-set.svg"),
            name: "stationery set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/smartTV.svg"),
            name: "50” smart TV",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-fridge.svg"),
            name: "mini fridge",
          },
          {
            iconUrl: require("@/assets/rooms/icon/refill-water-bottle.svg"),
            name: "refill water bottle",
          },
          {
            iconUrl: require("@/assets/rooms/icon/mini-bar.svg"),
            name: "mini bar",
          },
          {
            iconUrl: require("@/assets/rooms/icon/organic-tea.svg"),
            name: "organic tea",
          },
          {
            iconUrl: require("@/assets/rooms/icon/coffee-set.svg"),
            name: "coffee set",
          },
          {
            iconUrl: require("@/assets/rooms/icon/room-safe.svg"),
            name: "room safe",
          },
          {
            iconUrl: require("@/assets/rooms/icon/shower.svg"),
            name: "shower",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bathtub.svg"),
            name: "bathtub"
          },
          {
            iconUrl: require("@/assets/rooms/icon/bathroom-amenities.svg"),
            name: "bathroom amenities",
          },
          {
            iconUrl: require("@/assets/rooms/icon/linen-towels.svg"),
            name: "linen + towels",
          },
          {
            iconUrl: require("@/assets/rooms/icon/bean-bag.svg"),
            name: "beanbag",
          },
          {
            iconUrl: require("@/assets/rooms/icon/inspiration-book.svg"),
            name: "inspirational books",
          },
        ],
      },
    },
  }
};
