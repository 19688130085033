<template>
  <router-link
    :to="linkTo"
    class="saoltext font-light text-np-glimpse flex flex-row gap-x-1"
  >
    <div>
      <slot></slot>
    </div>
    <div class="flex transition duration-200 anchor-arrow">
      <img src="@/assets/neera-arrow.svg" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "NeeraAnchor",
  props: {
    linkTo: {
      type: [String],
    },
  },
};
</script>

<style scoped>
a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}
</style>
