<template>
  <Header></Header>
  <!-- <router-view /> -->
  <transition name="slide" mode="out">
    <div class="curtain w-screen h-screen bg-np-green flex justify-center items-center" v-if="initialLoading">
      <!-- Loading -->
      <img
        :src="headerImageLightUrl"
        class="w-40 h-40 absolute"
      />
    </div>
  </transition>
  <transition name="slide" mode="out-in">
    <div class="curtain w-screen h-screen bg-np-green flex justify-center items-center" v-if="loading !== false">
             <img
        :src="headerImageLightUrl"
        class="w-40 h-40 absolute"
      />
    </div>
  </transition>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { ref, inject } from 'vue'

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  setup() {
    const initialLoading = ref(true)
    setTimeout(() =>   initialLoading.value = false, 2000);

    const loading = inject('loading')

    return {
      initialLoading,
      loading,
      headerImageLightUrl: require("@/assets/logo.svg")
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

#nav a {
  font-weight: bold;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} 

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
} 

.fadeLong-enter-from,
.fadeLong-leave-to {
  opacity: 0;
} 

.fadeLong-enter-active,
.fadeLong-leave-active {
  transition: opacity 0.3s ease-in;
} 

.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}


.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

/* .slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}


.slide-enter-to {
  position: absolute;
  bottom: 0;
}


.slide-enter-from {
  position: absolute;
  bottom: -100%;
}


.slide-leave-to {
  position: absolute;
  bottom: -100%;
}


.slide-leave-from {
  position: absolute;
  bottom: 0;
} */
</style>
