import apiClient from "@/api/client";

export function addSubscription(email) {
  return apiClient.request({
    method: "POST",
    url: "api/subscribers",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      data: {
        email: email,
      },
    },
  });
}
