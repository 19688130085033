import { ref } from 'vue';

const loading = ref(false);

export function useLoading() {
    const setLoading = (value) => {
        loading.value = value
    };

    return { loading, setLoading };
}
  