import apiClient from "@/api/client";

export const getOfferType = (lang) => {
  return apiClient.request({
    method: "GET",
    url: `api/offer-types?locale=${lang}`,
  });
};

export const getOfferList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/offers?populate[0]=image&populate[1]=type&populate[2]=cardImage&sort[0]=pin:desc&sort[1]=id:desc&pagination[pageSize]=100&locale=${lang}${filter}`,
  });
};

export const getOtherOfferList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/offers?populate[0]=image&populate[1]=type&populate[2]=cardImage&sort[0]=pin:desc&sort[1]=id:desc&pagination[pageSize]=100&locale=${lang}${filter}`,
  });
};

export const getOfferById = (lang, offerId) => {
  return apiClient.request({
    method: "GET",
    url: `api/offers/${offerId}?populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};

export const getOfferBySlug = (lang, slug) => {
  return apiClient.request({
    method: "GET",
    url: `api/offers?filters[slug]=${slug}&populate=type,metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};
