import apiClient from "@/api/client";

export const getActivityList = (type_id,lang) => {
  let filter = type_id > 0 ? `&filters[type][id]=${type_id}` : ''
  return apiClient.request({
    method: "GET",
    url: `api/activities?populate[0]=image&populate[1]=cardImage&populate[2]=type&locale=${lang}${filter}&sort[0]=pin:desc&sort[1]=id:desc&pagination[pageSize]=100`,
  });
};

export const getActivityById = (lang, activityId) => {
  return apiClient.request({
    method: "GET",
    url: `api/activities/${activityId}?populate=metadata,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};

export const getActivityBySlug = (lang, slug) => {
  return apiClient.request({
    method: "GET",
    url: `api/activities?filters[slug]=${slug}&populate=metadata,type,image,body.images&pagination[pageSize]=100&locale=${lang}`,
  });
};

export const getActivityType = (lang) => {
  return apiClient.request({
    method: "GET",
    url: `api/activity-types?locale=${lang}&pagination[pageSize]=100`,
  });
};
