export const getSiteminderLink = (checkInDate, checkOutDate, guestNumber, bedNumber) => {
    const baseUrl = "https://app-apac.thebookingbutton.com/properties/neeraretreathoteldirect"
    const url = baseUrl + `?locale=en&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&items[0][adults]=${guestNumber}&items[0][beds]=${bedNumber}`
    
    return url
  };


  export const getSiteminderLinkDefault = () => {
    const baseUrl = "https://app-apac.thebookingbutton.com/properties/neeraretreathoteldirect"
    return baseUrl
  };