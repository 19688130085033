<template>
  <div
    v-if="special_items.length > 0"
    id="offers"
    class="py-18 lg:py-20 bg-np-fog"
  >
    <div
      data-aos="fade-up"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto space-y-6"
    >
      <div>
        <p class="header-special-offer text-left text-np-3xl">
          {{ titleList }}
        </p>
      </div>

      <div
        class="flex flex-row overflow-x-scroll lg:container lg:mx-auto lg:grid lg:grid-cols-4 gap-6"
      >
        <other-discover-item
          :image-url="getImageByFormat(item.attributes.cardImage, 'small')"
          :item-title="item.attributes.title"
          :item-description="item.attributes.description"
          :badge="item.attributes.type.data.attributes.name"
          :item-detail-path="`/${type}/${item.attributes.slug}`"
          :isPin="item.attributes.pin"
          class="lg:mb-8"
          v-for="(item, index) in special_items"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getOtherOfferList } from "@/api/endpoints/offers";
import { getActivityList } from "@/api/endpoints/activities";
import { getOtherNewsList } from "@/api/endpoints/news";
import OtherDiscoverItem from "@/components/OtherDiscoverItem.vue";
import to from "await-to-js";
import AOS from "aos";

export default {
  name: "SpecialOffer",
  components: {
    OtherDiscoverItem,
  },
  props: {
    titleList: {
      type: [String],
      default: "",
    },
    type: {
      type: [String],
      default: "offer",
    },
    subType: {
      type: [String],
      default: "0",
    },
    excludeId: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      lang: "en",
      special_items: [],
    };
  },
  watch: {
    excludeId: async function (excludeId) {
      await this.setItemList(this.type, this.subType, excludeId, this.lang);
    },
  },
  async mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
    await this.setItemList(this.type, this.subType, this.excludeId, this.lang);
  },
  methods: {
    getImageByFormat(image, type) {
      if (image.data) return image.data.attributes.formats[type].url;
      return "";
    },
    async setItemList(type, subType, excludeId, lang) {
      let err, response, special_items;
      if (type == "offer") {
        [err, response] = await to(getOtherOfferList(subType, lang));
      } else if (type == "experiences/activity") {
        [err, response] = await to(getActivityList(subType, lang));
      } else if (type == "whats-new") {
        [err, response] = await to(getOtherNewsList(subType, lang));
      }

      if (err) {
        return { errorMessage: err?.response?.data?.error || err.message };
      }
      special_items = response.data.data;

      this.special_items = [];

      special_items.slice(0, 4).forEach((element) => {
        if (element.id != excludeId) this.special_items.push(element);
      });
    },
  },
};
</script>

<style scoped>
.booking {
  background: rgba(206, 205, 200, 1);
}

input {
  background: transparent;
  border: none;
  position: relative;
  display: block;
  outline: none;
  width: 10vw;
  height: 42px;
  padding: 10px;
  color: #333;
  border: 0;
  outline: 0;
  background: transparent;
}
::-webkit-input-placeholder {
  color: #333 !important;
}
:-moz-placeholder {
  color: #333 !important;
}
::-moz-placeholder {
  color: #333 !important;
}
:-ms-input-placeholder {
  color: #333 !important;
}

button {
  border-radius: 10px;
  height: 42px;
  border: 1px solid;
  border-image-source: linear-gradient(0deg, #050505, #050505),
    linear-gradient(0deg, #2f342b, #2f342b);
}

.border-bottom-dash {
  border-bottom: 1px solid black;
  border-style: dashed;
}

.text-litepie-secondary-700,
.placeholder-litepie-secondary-400,
.text-litepie-secondary-100,
.placeholder-litepie-secondary-500 {
  color: #666;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
}

.header2 {
  font-weight: 300;
}

.header {
  width: 100%;
  height: 725px;
}

.img-para {
  width: 459px;
  height: 652px;
}

.title-para {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.header-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.title-special-offer {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #5d6145;
}

.description-special-offer {
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content-para {
  font-family: "Roboto";
  color: #5c623f;
}

.padding {
  padding-left: 10%;
  padding-right: 10%;
}

.special-offer {
  background: #e7e5dc;
}

.gallery-label {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #f0efea;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  border-radius: 0px 40px 0px 0px;
}
</style>
