<template>
  <button
    @click="checkAvailablity"
    class="saoltext text-np-light bg-white rounded-np-10 whitespace-nowrap overflow-hidden"
  >
    <div
      class="transition py-2 px-4 h-full w-full bg-np-glimpse hover:opacity-60"
    >
      book now
    </div>
  </button>
</template>
<script>
import dayjs from "dayjs";
import { getSiteminderLink } from "@/api/siteminder";

export default {
  name: "BookingButton",
  updated() {
    if (this.$screen) {
      this.isPhone = this.$screen.width < 1024;
    }
  },
  mounted() {
    this.isPhone = this.$screen.width < 1024;
  },
  methods: {
    checkAvailablity() {
      const temp = new Date();
      const tomorrow = new Date(temp);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const url = getSiteminderLink(
        dayjs(temp).format("YYYY-MM-DD"),
        dayjs(tomorrow).format("YYYY-MM-DD"),
        1,
        1
      );
      if (this.isPhone) window.location.href = url;
      else window.open(url);
    },
  },
};
</script>
