<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 px-4 lg:px-8 max-w-screen-lg mx-auto"
  >
    <div class="flex flex-col w-full text-left space-y-4 justify-center">
      <p class="title text-np-3xl text-np-green break-words" v-html="title"></p>
      <slot></slot>
    </div>
    <div
      class="justify-center order-first"
      :class="{
        'lg:order-first': imgPosition === 'left',
        'lg:order-last': imgPosition === 'right',
      }"
    >
      <div class="relative w-full aspect-4-3 rounded-tr-np-lg overflow-hidden">
        <img class="absolute object-cover w-full h-full" :src="imageUrl" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExperienceCard",
  props: {
    title: {
      type: [String],
    },
    imageUrl: {
      type: [String],
    },
    imgPosition: {
      type: [String],
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
}

.title sup {
  top: -20px;
}
</style>
