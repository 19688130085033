<template>
  <div
    id="footer"
    class="flex flex-col lg:flex-row py-10 xl:py-16 text-np-light"
  >
    <div
      id="mobile-footer"
      class="px-4 lg:px-8 flex flex-col space-y-10 md:hidden"
    >
      <div class="flex relative flex-col w-full font-light text-left space-y-4">
        <div class="space-y-2">
          <div>
            <p class="saoltext font-light text-np-xl">
              be the start of the ripple.
            </p>
          </div>
          <div class="flex flex-col">
            <div>
              <p class="robototext font-light text-np-sm">
                sign up for the latest news, updates, and exclusive offers.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="space-y-2">
            <div
              class="flex font-light text-np-sm border-b border-solid border-white rounded-tr-np-sm overflow-hidden"
            >
              <input
                for="email"
                type="email"
                class="bg-np-fog px-4 py-2 w-full text-np-dark placeholder:text-np-grey placeholder:text-np-base"
                placeholder="input e-mail :)"
                required
                v-model="email"
                @keyup="handleChange"
              />
            </div>
            <div class="flex justify-end">
              <a
                @click="subscription"
                class="text-right text-np-sm font-light text-np-light saoltext cursor-pointer"
                ><div class="flex w-auto items-left">
                  <p>submit</p>
                  <div class="flex transition duration-200 anchor-arrow px-2">
                    <img src="@/assets/neera-arrow-light.svg" />
                  </div></div
              ></a>
            </div>
          </div>
          <!-- <div class="grid grid-cols-4">
            <div class="flex col-span-3">
              <input
                for="email"
                type="email"
                class="p-2 w-full"
                placeholder="input e-mail :)"
                required
                v-model="email"
                @keyup="handleChange"
              />
            </div>
            <div class="flex col-span-1">
              <button
                @click="subscription"
                class="py-2 px-4 robototext text-np-light bg-np-glimpse text-np-sm rounded whitespace-nowrap"
              >
                subscribe
              </button>
            </div>
          </div> -->
          <div v-if="message">
            <span class="error-message text-sm">{{ message }}</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-y-10 w-full text-left">
        <div class="flex flex-col w-full pr-8 space-y-2">
          <router-link to="/" class="saoltext font-light text-np-xl"
            >home</router-link
          >
          <ul class="robototext font-light text-np-sm space-y-2">
            <li><router-link to="/about">about neera</router-link></li>
            <li>
              <router-link to="/about/eco-conscious"
                >neera eco–conscious</router-link
              >
            </li>
            <li>
              <router-link to="/about/neighborhoods"
                >neera neighborhoods</router-link
              >
            </li>
          </ul>
        </div>
        <div class="flex flex-col w-full pr-8 space-y-2">
          <router-link to="/rooms" class="saoltext font-light text-np-xl"
            >rooms</router-link
          >
          <ul class="robototext font-light text-np-sm space-y-2">
            <li><router-link to="/rooms/well">well</router-link></li>
            <li><router-link to="/rooms/pond">pond</router-link></li>
            <li><router-link to="/rooms/mindful">mindful</router-link></li>
            <li><router-link to="/rooms/lagoon">lagoon</router-link></li>
            <li><router-link to="/rooms/lake">lake</router-link></li>
          </ul>
        </div>
        <div class="flex flex-col w-full space-y-2">
          <router-link to="/experiences" class="saoltext font-light text-np-xl"
            >experiences</router-link
          >
          <ul class="robototext font-light text-np-sm space-y-2">
            <li>
              <router-link to="/experiences/activities"
                >neera activities</router-link
              >
            </li>
            <li>
              <router-link to="/experiences/eco-wedding"
                >neera eco–wedding</router-link
              >
            </li>
            <li>
              <router-link to="/experiences/spaces">neera spaces</router-link>
            </li>
          </ul>
        </div>

        <div class="flex flex-col w-full space-y-2">
          <router-link to="/eat-drink" class="saoltext font-light text-np-xl"
            >eat & drink</router-link
          >
          <ul class="robototext font-light text-np-sm space-y-2">
            <li><router-link to="/eat-drink#season">season</router-link></li>
            <li>
              <router-link to="/eat-drink#cafe">neera slow bar</router-link>
            </li>
          </ul>
        </div>
        <div class="flex flex-col pr-8 space-y-2">
          <router-link
            to="/media-inquiry"
            class="saoltext font-light text-np-xl"
            >media room</router-link
          >
          <ul class="robototext font-light text-np-sm space-y-2">
            <li><router-link to="/whats-new">what's new</router-link></li>
            <li>
              <router-link to="/media-inquiry">media inquiry</router-link>
            </li>
          </ul>
        </div>
        <div class="flex flex-col w-full space-y-2">
          <router-link to="/offers" class="saoltext font-light text-np-xl"
            >offers</router-link
          >
          <router-link to="/galleries" class="saoltext font-light text-np-xl"
            >galleries</router-link
          >
        </div>
        <div class="flex flex-col w-full col-span-2 space-y-4">
          <div class="space-y-2">
            <div class="space-y-2">
              <p class="saoltext font-light text-np-xl">contact us</p>
              <ul class="robototext font-light text-np-sm">
                <li>
                  1/3 Moo 4 Thathalad<br />
                  Samphran Nakhon Pathom<br />73110 Thailand
                </li>
              </ul>
            </div>
            <div>
              <a
                class="robototext font-light text-np-sm"
                href="https://goo.gl/maps/xz2ohKnDiKr6A9XC9"
                target="_blank"
                >view map</a
              >
            </div>
          </div>
          <div class="flex flex-row space-x-6">
            <a
              class="flex w-6 h-6 facebook-logo"
              href="https://facebook.com/neeraretreathotel"
              target="_blank"
              variant="link"
            >
              <img src="@/assets/facebook.svg" />
            </a>
            <a
              class="flex w-6 h-6"
              href="https://instagram.com/neera.retreat.hotel"
              target="_blank"
              variant="link"
            >
              <img src="@/assets/instagram.svg" />
            </a>
            <a
              class="flex w-6 h-6"
              href="https://lin.ee/IJ8K710"
              target="_blank"
              variant="link"
            >
              <img src="@/assets/line.svg" />
            </a>
            <a
              class="flex w-6 h-6"
              href="https://twitter.com/neeraretreat"
              target="_blank"
              variant="link"
            >
              <img src="@/assets/twitter.svg" />
            </a>
          </div>
          <div>
            <p>
              <a
                class="robototext font-light text-np-sm"
                href="tel:+6634324034"
                >+66 34 324 034</a
              >
            </p>
            <p>
              <a
                class="robototext font-light text-np-sm"
                href="mailto:info@neeraretreathotel.com"
                >info@neeraretreathotel.com</a
              >
            </p>
          </div>
        </div>
        <div class="flex robototext font-light text-np-sm col-span-2">
          <p>
            {{ `© 2022 neera retreat. all rights reserved.`.toLowerCase() }}
          </p>
        </div>
      </div>
    </div>
    <div
      id="desktop-footer"
      class="px-4 lg:px-8 max-w-screen-lg mx-auto hidden md:flex"
    >
      <div class="grid grid-cols-4 gap-x-8">
        <!-- SUBSCRIBE SECTION -->
        <div
          class="flex relative flex-col w-full font-light text-left space-y-4"
        >
          <div class="space-y-2">
            <div>
              <p class="saoltext font-light text-np-xl">
                be the start of the ripple.
              </p>
            </div>
            <div class="flex flex-col">
              <div>
                <p class="robototext font-light text-np-sm">
                  sign up for the latest news, updates, and exclusive offers.
                </p>
              </div>
            </div>
          </div>
          <div class="space-y-2">
            <div
              class="flex font-light text-np-sm border-b border-solid border-white rounded-tr-np-sm overflow-hidden"
            >
              <input
                for="email"
                type="email"
                class="bg-np-fog px-4 py-2 w-full text-np-dark placeholder:text-np-grey placeholder:text-np-base"
                placeholder="input e-mail :)"
                required
                v-model="email"
                @keyup="handleChange"
              />
            </div>
            <div class="flex justify-end">
              <a
                @click="subscription"
                class="text-right text-np-sm font-light text-np-light saoltext cursor-pointer"
                ><div class="flex w-auto items-left">
                  <p>submit</p>
                  <div class="flex transition duration-200 anchor-arrow px-2">
                    <img src="@/assets/neera-arrow-light.svg" />
                  </div></div
              ></a>
            </div>
          </div>

          <div class="flex robototext font-light text-np-sm pt-8">
            <p>© 2022 neera retreat. all rights reserved.</p>
          </div>
        </div>

        <!-- NAVIGATION SECTION -->
        <div class="col-span-3 grid grid-cols-4 gap-x-8 gap-y-10 text-left">
          <div class="flex flex-col space-y-2">
            <router-link to="/" class="saoltext font-light text-np-xl"
              >home</router-link
            >
            <ul class="robototext font-light text-np-sm space-y-2">
              <li><router-link to="/about">about neera</router-link></li>
              <li>
                <router-link to="/about/eco-conscious"
                  >neera eco–conscious</router-link
                >
              </li>
              <li>
                <router-link to="/about/neighborhoods"
                  >neera neighborhoods</router-link
                >
              </li>
            </ul>
          </div>
          <div class="flex flex-col space-y-2">
            <router-link to="/rooms" class="saoltext font-light text-np-xl"
              >rooms</router-link
            >
            <ul class="robototext font-light text-np-sm space-y-2">
              <li><router-link to="/rooms/well">well</router-link></li>
              <li><router-link to="/rooms/pond">pond</router-link></li>
              <li><router-link to="/rooms/mindful">mindful</router-link></li>
              <li><router-link to="/rooms/lagoon">lagoon</router-link></li>
              <li><router-link to="/rooms/lake">lake</router-link></li>
            </ul>
          </div>
          <div class="flex flex-col space-y-2">
            <router-link
              to="/experiences"
              class="saoltext font-light text-np-xl"
              >experiences</router-link
            >
            <ul class="robototext font-light text-np-sm space-y-2">
              <li>
                <router-link to="/experiences/activities"
                  >neera activities</router-link
                >
              </li>
              <li>
                <router-link to="/experiences/eco-wedding"
                  >neera eco–wedding</router-link
                >
              </li>
              <li>
                <router-link to="/experiences/spaces">neera spaces</router-link>
              </li>
            </ul>
          </div>
          <div class="flex flex-col row-span-2 space-y-4">
            <div class="space-y-2">
              <p class="saoltext font-light text-np-xl">contact us</p>
              <ul class="robototext font-light text-np-sm">
                <li>
                  1/3 Moo 4 Thathalad Samphran Nakhon Pathom 73110 Thailand
                </li>
              </ul>
            </div>
            <div>
              <a
                class="robototext font-light text-np-sm"
                href="https://goo.gl/maps/xz2ohKnDiKr6A9XC9"
                target="_blank"
                >view map</a
              >
            </div>
            <div class="flex flex-row items-center space-x-5">
              <a
                class="flex w-6 h-6 facebook-logo"
                href="https://facebook.com/neeraretreathotel"
                target="_blank"
                variant="link"
              >
                <img src="@/assets/facebook.svg" />
              </a>
              <a
                class="flex w-6 h-6"
                href="https://instagram.com/neera.retreat.hotel"
                target="_blank"
                variant="link"
              >
                <img src="@/assets/instagram.svg" />
              </a>
              <a
                class="flex w-6 h-6"
                href="https://lin.ee/IJ8K710"
                target="_blank"
                variant="link"
              >
                <img src="@/assets/line.svg" />
              </a>
              <a
                class="flex w-6 h-6"
                href="https://twitter.com/neeraretreat"
                target="_blank"
                variant="link"
              >
                <img src="@/assets/twitter.svg" />
              </a>
            </div>
            <div>
              <p>
                <a
                  class="robototext font-light text-np-sm"
                  href="tel:+66034324034"
                  >+66 34 324 034</a
                >
              </p>
              <p>
                <a
                  class="robototext font-light text-np-sm"
                  href="mailto:info@neeraretreathotel.com"
                  >info@neeraretreathotel.com</a
                >
              </p>
            </div>
          </div>
          <div class="flex flex-col pr-8 space-y-2">
            <router-link to="/eat-drink" class="saoltext font-light text-np-xl"
              >eat & drink</router-link
            >
            <ul class="robototext font-light text-np-sm space-y-2">
              <li><router-link to="/eat-drink#season">season</router-link></li>
              <li>
                <router-link to="/eat-drink#cafe">neera slow bar</router-link>
              </li>
            </ul>
          </div>
          <div class="flex flex-col pr-8 space-y-2">
            <router-link
              to="/media-inquiry"
              class="saoltext font-light text-np-xl"
              >media room</router-link
            >
            <ul class="robototext font-light text-np-sm space-y-2">
              <li><router-link to="/whats-new">what's new</router-link></li>
              <li>
                <router-link to="/media-inquiry">media inquiry</router-link>
              </li>
            </ul>
          </div>
          <div class="flex flex-col pr-8 space-y-2">
            <router-link to="/offers" class="saoltext font-light text-np-xl"
              >offers</router-link
            >
            <router-link to="/galleries" class="saoltext font-light text-np-xl"
              >galleries</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addSubscription } from "@/api/common";
import to from "await-to-js";
import Swal from "sweetalert2";

export default {
  name: "Home",
  data() {
    return {
      email: null,
      message: "",
    };
  },
  components: {},
  watch: {
    "$route.path": {
      handler: async function () {
        this.scrollToTop();
      },
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    validateEmail(email) {
      if (!email) this.message = "";
      const emailPatternRegx = new RegExp(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      );

      if (!emailPatternRegx.test(email)) {
        this.message = "Please enter a valid email address";
      } else {
        this.message = "";
      }
    },

    handleChange(e) {
      this.email = e.target.value;
      this.validateEmail(e.target.value);
    },
    async subscription() {
      if (!this.email) {
        Swal.fire({
          title: "Error!",
          text: "Please check your email",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }
      const [error, response] = await to(addSubscription(this.email)); // eslint-disable-line no-unused-vars
      if (!response) {
        this.message = "Please check your email";
        Swal.fire({
          title: "Error!",
          text: "Please check your email",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        this.message = "";
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "You subscribe success",
          showConfirmButton: false,
          timer: 1500,
        });
        this.email = "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
  background-color: #5c623f;
}

.header {
  font-size: 2rem;
}

li {
  font-weight: 300;
  text-align: left;
}

button {
  color: white;
  /* border: 1px solid white;
  border-radius: 10px; */
}

input,
select {
  border: none;
  position: relative;
  display: block;
  outline: none;
  border: 0;
  outline: 0;
}

.error-message {
  color: red;
}

::placeholder {
  color: #2f342b;
  font-size: 14px;
  font-weight: 300;
}

a:hover .anchor-arrow {
  transform: translateX(0.25rem);
}
</style>
