<template>
  <Carousel
    class="w-full h-full"
    :autoplay="5000"
    :transition="1500"
    :wrap-around="true"
  >
    <Slide
      class="flex relative h-full"
      v-for="(image, index) in images"
      :key="index"
    >
      <div class="absolute centered text-np-72">
        <p data-aos="fade-up" class="saoltext text-white">{{ image.title }}</p>
      </div>
      <div class="h-120 lg:h-screen w-full">
        <img :src="image.image_url" class="object-cover w-full h-full" />
      </div>
    </Slide>

    <template #addons>
      <navigation>
        <template #next>
          <div>
            <img src="@/assets/chevron-right.svg" />
          </div>
        </template>
        <template #prev>
          <div>
            <img src="@/assets/chevron-left.svg" />
          </div>
        </template>
      </navigation>
    </template>
  </Carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
// import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";

export default {
  name: "GalleryItem",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    images: {
      type: [Array],
    },
  },

  computed: {
    image() {
      return this.images[0];
    },
    galleries() {
      return this.images.filter((word, index) => index > 0);
    },
  },
};
</script>

<style>
.carousel__prev {
  background-color: transparent;
  transform: translate(50%, -50%);
}

.carousel__next {
  background-color: transparent;
  transform: translate(-50%, -50%);
}
</style>
